import React from 'react';

import classes from './Error.module.css';

const error = (props) => (
    <div className={classes.Content}>
        Error
    </div>
);

export default error;