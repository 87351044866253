// Category
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAIL = 'FETCH_CATEGORIES_FAIL';

export const FETCH_CATEGORY_CHANNELS_SUCCESS = 'FETCH_CATEGORY_CHANNELS_SUCCESS';
export const FETCH_CATEGORY_CHANNELS_FAIL = 'FETCH_CATEGORY_CHANNELS_FAIL';

export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS';
export const FETCH_CATEGORY_FAIL = 'FETCH_CATEGORY_FAIL';

// Channel
export const FETCH_CHANNELS_SUCCESS = 'FETCH_CHANNELS_SUCCESS';
export const FETCH_CHANNELS_FAIL = 'FETCH_CHANNELS_FAIL';

// Comment
export const FETCH_COMMENTS_SUCCESS = 'FETCH_COMMENTS_SUCCESS';
export const FETCH_COMMENTS_FAIL = 'FETCH_COMMENTS_FAIL';

export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_FAIL = 'ADD_COMMENT_FAIL';

export const UPDATE_COMMENTS_SUCCESS = 'UPDATE_COMMENTS_SUCCESS';

export const FETCH_CHANNEL_SUCCESS = 'FETCH_CHANNEL_SUCCESS';
export const FETCH_CHANNEL_FAIL = 'FETCH_CHANNEL_FAIL';

// UI
export const UPDATE_HEADER_HEIGHT = 'UPDATE_HEADER_HEIGHT';
export const UPDATE_HEADER_CHANNEL_WIDTH = 'UPDATE_HEADER_CHANNEL_WIDTH';
export const UPDATE_SEARCH_TEXT = 'UPDATE_SEARCH_TEXT';
export const UPDATE_SEARCH_TEXT_TEMP = 'UPDATE_SEARCH_TEXT_TEMP';
export const UPDATE_NAVBAR_SEARCH_TEXT = 'UPDATE_NAVBAR_SEARCH_TEXT';
export const VISIBILITY_NAVBAR_SEARCH_RESULTS = 'VISIBILITY_NAVBAR_SEARCH_RESULTS';
export const UPDATE_RECOMMENDED_LIST_MORE = 'UPDATE_RECOMMENDED_LIST_MORE';

// Search
export const FETCH_NAVBAR_SEARCH_RESULTS_SUCCESS = 'FETCH_NAVBAR_SEARCH_RESULTS_SUCCESS';
export const FETCH_NAVBAR_SEARCH_RESULTS_FAIL = 'FETCH_NAVBAR_SEARCH_RESULTS_FAIL';